import * as actionLabels from "../../actionLabels";

export const getBalanceStart = (payload) => {
  return {
    type: actionLabels.GET_BALANCE_START,
    payload,
  };
};
export const getBalance = (payload) => {
  return {
    type: actionLabels.GET_BALANCE,
    payload,
  };
};
export const getBalanceSuccess = (payload) => {
  return {
    type: actionLabels.GET_BALANCE_SUCCESS,
    payload,
  };
};
export const getBalanceFail = (payload) => {
  return {
    type: actionLabels.GET_BALANCE_FAIL,
    payload,
  };
};
