/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Switch, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { guestRoutes } from "./routes";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { useEagerConnect, useInactiveListener } from "./hooks";
import WrongNetworkModal from "./views/WrongNetworkModal/WrongNetworkModal";
import Layout from "./views/Layout/Layout";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getBalance } from "./store/actions";
import Spinner from "./views/Spinner/Spinner";
toast.configure();

const App = () => {
  const [activatingConnector, setActivatingConnector] = useState();

  const { connector, active, chainId, account } = useWeb3React();

  const dispatch = useDispatch();
  const triedEager = useEagerConnect();

  useInactiveListener(!triedEager || !!activatingConnector);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    if (active) localStorage.setItem("shouldEggerConnect", "true");
  }, [active]);

  useEffect(() => {
    try {
      if (account) dispatch(getBalance({ account }));
    } catch (err) {}
  }, [account]);

  const onChangeNetworkClick = async () => {
    const connectedWallet = localStorage.getItem("connectedWallet");
    if (connectedWallet === "metamask") {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x38" }],
        });
        window.location.reload();
      } catch (error) {
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  rpcUrls: ["https://bsc-dataseed.binance.org/"],
                  chainId: "0x38",
                  nativeCurrency: {
                    name: "Binance",
                    symbol: "BNB",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://bscscan.com"],
                  chainName: "Bsc Mainnet",
                },
              ],
            });
            window.location.reload();
          } catch (addError) {}
        } else {
          // if no window.ethereum then MetaMask is not installed
          alert(
            "MetaMask is not installed. Please consider installing it: https://metamask.io/download.html"
          );
        }
      }
    }
  };

  let mainContent = (
    <Layout>
      {guestRoutes.map((route) =>
        route.component
          ? route.redirectRoute === undefined && (
              <Route
                key={route.name}
                path={route.path}
                exact={route.exact}
                name={route.name}
              >
                <route.component />
              </Route>
            )
          : route.redirectRoute && <Redirect key={route.name} to={"/"} />
      )}
    </Layout>
  );

  return (
    <React.Suspense fallback={<Spinner />}>
      <Switch>{mainContent}</Switch>
      <WrongNetworkModal
        show={
          chainId !== parseInt(process.env.REACT_APP_CURRENT_CHAINID) && active
        }
        onChangeNetworkClick={onChangeNetworkClick}
      />
      <div
        style={{ position: "fixed", top: "92px", right: "92px", zIndex: "3" }}
      >
        <ToastContainer containerId="appLayoutToaster" />
      </div>
    </React.Suspense>
  );
};

export default App;
