/* eslint-disable react-hooks/exhaustive-deps */
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import React, { useRef, useEffect, useState } from "react";
import { injected } from "./../../utils/connectors";
import MetaMaskOnboarding from "@metamask/onboarding";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";

const ConnectWallet = () => {
  const { account, activate, error, connector } = useWeb3React();
  const [activatingConnector, setActivatingConnector] = useState();
  const onboarding = useRef();

  const onConnectWithMetamaskClick = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      setActivatingConnector(injected);
      activate(injected, undefined, true)
        .then(() => {
          toast.success("Connected successfully");
        })
        .catch(() => {
          // setTried(true);
        });
      localStorage.setItem("connectedWallet", "metamask");
    } else {
      onboarding.current.startOnboarding();
    }
  };

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (account && account.length > 0) {
        onboarding.current.stopOnboarding();
      }
    }
  }, [account]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (account && account.length > 0) {
        onboarding.current.stopOnboarding();
      }
    }
  }, [account]);

  useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      toast.error("Please connect to the Binance Smart Chain");
    }
  }, [error]);

  return (
    <div className="account">
      <Button
        className="btn btn-second connectWalletBox"
        title="Connect your wallet"
        onClick={onConnectWithMetamaskClick}
      />
    </div>
  );
};

export default ConnectWallet;
