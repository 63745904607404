import React, { useEffect } from "react";

const Modal = (props) => {
  const {
    children,
    modalId,
    modalClass,
    isOpen,
    modalContentClass,
    modalBodyContent,
  } = props;

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = isOpen ? "hidden" : "auto";
    return () => {
      body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className="modal fade show modal_text"
          id={modalId || ""}
          tabIndex="-1"
          role="dialog"
          aria-labelledby=""
          aria-hidden="true"
          style={{
            display: "block",
            zIndex: 1041,
          }}
        >
          <div
            className="modal-backdrop fade show"
            style={{ cursor: "pointer" }}
          />
          <div
            className={`modal-dialog ${modalClass || ""} modal-dialog-centered`}
            role="document"
            style={{ zIndex: 1045 }}
          >
            <div
              className={`modal-content animated modalheight ${
                modalContentClass || ""
              } fadeInDownBig p-2`}
            >
              <div className={`${modalBodyContent || ""}`}>{children}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
