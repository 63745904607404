import Web3 from "web3";

const getWeb3 = () => {
  const { ethereum } = window;
  let provider = ethereum;
  if (ethereum.providers) {
    provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
  }
  const web3 = new Web3(provider);
  return web3;
};
export default getWeb3;
