import { all, takeEvery, put } from "redux-saga/effects";
import { getBalanceFail, getBalanceSuccess } from "../../actions";
import * as actionLabels from "../../actionLabels";
import TokenAbi from "../../../abis/TokenAbi.json";
import Web3 from "web3";

function* getBalanceSaga({ payload }) {
  try {
    const { account } = payload;
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        process.env.REACT_APP_CURRENT_WALLETCONNECT_RPC
      )
    );
    const tokenContract = new web3.eth.Contract(
      TokenAbi,
      process.env.REACT_APP_TOKEN_ADDRESS
    );

    const balance = yield tokenContract.methods.balanceOf(account).call();
    yield put(getBalanceSuccess({ balance }));
  } catch (err) {
    yield put(getBalanceFail({ message: err.message }));
  }
}

export default function* rootsaga() {
  yield all([yield takeEvery(actionLabels.GET_BALANCE, getBalanceSaga)]);
}
