import React from "react";

const Button = ({ title, onClickButton, ...props }) => (
  <button
    className="btn btn-primary"
    onClick={onClickButton}
    {...props}
  >
    {title}
    {props.children}
  </button>
);

export default Button;
