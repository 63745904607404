import React from "react";
import Modal from "../ConnectWallet/Modal";

const WrongNetworkModal = ({ onChangeNetworkClick, show }) => {
  return (
    <Modal isOpen={show}>
      <div
        className="col-xs-12"
        style={{ textAlign: "center", padding: "1vw" }}
      >
        <div style={{ fontWeight: "700", marginTop: 4, marginBottom: 16 }}>
          You're currently on wrong network
        </div>
        <div style={{ marginTop: 4, marginBottom: 16 }}>
          Obortch only supports <strong>BSC Mainnet</strong> network, please
          switch to <strong>BSC Mainnet</strong> manually or click Switch
          network button
        </div>
        <button className="modalButton" onClick={onChangeNetworkClick}>
          Change Network
        </button>
      </div>
    </Modal>
  );
};

export default WrongNetworkModal;
