import * as actionLabels from "../../actionLabels";

const initialState = {
  balance: 0,
  message: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionLabels.GET_BALANCE_SUCCESS: {
      const { balance } = action.payload;
      return {
        ...state,
        balance,
        message: "",
      };
    }
    case actionLabels.GET_BALANCE_FAIL: {
      const { message } = action.payload;
      return {
        ...state,
        balance: 0,
        message,
      };
    }
    default:
      return state;
  }
};
