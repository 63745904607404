import React from "react";
export const guestRoutes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    component: React.lazy(() => import("../../views/Home/Home")),
  },
  {
    redirectRoute: true,
    name: "redirect",
    path: "/",
  },
];
