/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import logo from "../../assets/img/logo.png";
import ConnectWallet from "../ConnectWallet/ConnectWallet";
import { useStakingContract } from "../../hooks";
import { toast } from "react-toastify";

const Layout = (props) => {
  const { account, chainId } = useWeb3React();
  const stakingContract = useStakingContract();

  const [isAdmin, setIsAdmin] = useState(false);

  const getAdmin = async () => {
    try {
      const contractInstance = await stakingContract;
      const owner = await contractInstance?.methods?.owner().call();
      setIsAdmin(account === owner);
    } catch (err) {}
  };

  const copyAddress = async (address) => {
    try {
      await navigator.clipboard.writeText(address);
      toast.success("Address Copied");
    } catch (err) {
      toast.error("Somthing Goes Wrong");
    }
  };

  useEffect(() => {
    if (account) getAdmin();
  }, [account]);

  return (
    <>
      <div className="nav-head mb-200">
        <div className="nav-header">
          <Link to="/">
            <img src={logo} alt="white" className="nav-logo" />
          </Link>
          <div className="navMiddle">
            {isAdmin && account ? "Create OBOT staking pools" : "OBOT staking"}
          </div>
          {chainId === parseInt(process.env.REACT_APP_CURRENT_CHAINID) &&
          account ? (
            <div className="account">
              <Button
                className="btn btn-second"
                title={`Connected: ${account.substring(0, 6)}...`}
                onClick={() => copyAddress(account)}
              />
            </div>
          ) : (
            <ConnectWallet />
          )}
        </div>
      </div>
      {props.children}
    </>
  );
};

export default withRouter(Layout);
